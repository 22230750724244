//Author June Yee Leow
//Date Oct 3, 2017
//The action for login and logout related operation
//REDUX ACTION
import {asyncPost} from '../util/util';

//function that takes in a credential object that contains email and password for login
export const login = (credential,control) => {
  control.noToken = true;
  let url = '/session/login';
  let data = [
    {
      field:'email',
      value:credential.email
    },
    {
      field:'password',
      value:credential.password
    },
    {
      field:'role',
      value:'client'
    }
  ];

  let successAction = 'LOGIN_SUCCESS';
  let failedAction = 'LOGIN_FAILED';
  let successMsg = 'Login successfully.';
  let failedMsg = 'Login failed, please make sure you email and password combination is correct.';

  return (dispatch) => {
    let successFunc = (response)=>{console.log(response);
      if(response.data.code==='00'){
        dispatch(
          {type:successAction,
            payload:response.data
          }
        );
      }
      else{
        console.log(failedAction)
        dispatch(
          {type:failedAction,
            payload:response.data.message
          }
        );
      }
    };

    let failedFunc = (error)=>{
      dispatch(
        {type:failedAction,
          payload:error
        }
      );
    };


    asyncPost(url,data,control,successFunc,failedFunc,successMsg,failedMsg);
  }
}

//function that perform logout by sending the refresh token to server to deactivate it
export const logout = (control) => {
  localStorage.setItem('token', '');
  localStorage.setItem('rtoken', '');

  return (dispatch) => {
  dispatch(
      {type:'LOGOUT_SUCCESS',
        payload:null
      }
    );
  }

  // let url = '/session/logout';
  // let data = [
  //   {
  //     field:'rtoken',
  //     value:localStorage.getItem('rtoken')
  //   }
  // ];
  //
  // let successAction = 'LOGOUT_SUCCESS';
  // let failedAction = 'LOGOUT_FAILED';
  //
  // return (dispatch) => {
  //     let successFunc = (response)=>{
  //       dispatch(
  //         {type:successAction,
  //           payload:response
  //         }
  //       );
  //     };
  //
  //     let failedFunc = (error)=>{
  //       dispatch(
  //         {type:failedAction,
  //           payload:error
  //         }
  //       );
  //     };
  //
  //   asyncPost(url,data,control,successFunc,failedFunc);
  // }
}
