//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDate, formatDateTime} from '../../util/util';
import {Button, Col, Row, Input, Card, CardHeader, CardBody} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
//initialize the state
const initialState = {
  appraisal:{},
  newFeeTurnTime:{},
  turntime:'',
  message:'',
  fee:'',
  showConfirmation:false, 
};

//reducer function that perform state update
const reducer = getReducer();


const BidResponse  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let appraiserFk = useParams().appraiserFk;
  let bidID = useParams().bidID;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
    appraiserFk:appraiserFk,
    bidID:bidID,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const updateFeeTurntime = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'appraiserFk',
        value:state.appraiserFk
      },
      {
        field:'bidID',
        value:state.bidID
      },
      {
        field:'fee',
        value:state.fee
      },
      {
        field:'turntime',
        value:state.turntime
      },
      {
        field:'message',
        value:state.message
      },
    ];
   
    let callBack = apiCallBack([{state:'newFeeTurnTime', key:'data'},{state:'showConfirmation', value:true}]);
    httpPut('appraisal/bid/response/update', parameters, 'Fee and Turnaround time updated successfully. You may now close this page.', 'Oops, something went wrong and could not update the fee and turnaround time. Please try again later.', callBack);
  }

  const getAppraisal = () => {
    let callBack = apiCallBack([{state:'appraisal', key:'data'}]);
    httpGet('appraisal/public/'+state.id, '', 'Oops, something went wrong and could not load appraisal. Please try again later.', callBack);
  }

  //render
  let content;

  let turntime = null;
  if(state.turntime&&state.turntime!=='')
    turntime = moment(state.turntime).toDate();

  if(state.showConfirmation&&state.newFeeTurnTime.fee){
    content = <div>
      <center>
        <div>
          You have updated the fee to <b>${formatNumber(state.newFeeTurnTime.fee)}</b> and turntime to <b>{formatDate(state.newFeeTurnTime.turtime)}</b> on {formatDateTime(state.newFeeTurnTime.datetime_created)}
        </div>
        <br/><br/><br/>
        <b style={{fontSize:'18px'}}>Thank you for submitting the information! You may now close this page.</b>
        <br/><br/><br/>
      </center>
    </div>
  }else{
    content = <div><form onSubmit={updateFeeTurntime}>
      <Row>
        <Col sm="4">
          <label>Fee</label>
        </Col>
        <Col sm="8">
          <label>$</label>&nbsp;<div className='display-inline'><Input required={true} type="text" pattern="[0-9]*"value={state.fee} onChange={(e)=>setState({fee:e.target.value})}/></div>
        </Col>
      </Row>
      <Row style={{marginTop:'5px'}}>
        <Col sm="4">
          <label>Turnaround Time</label>
        </Col>
        <Col sm="8">
          &nbsp;&nbsp;&nbsp;
          <DatePicker
            className="form-control"
            selected={turntime}
            onChange={(text)=>{(text)&&setState({turntime:text.toLocaleDateString("en-CA")})}}
          />                    
        </Col>
      </Row>
      <br/>
      <Row style={{marginTop:'5px'}}>
        <Col sm="4">
          <label>Message</label>
        </Col>
        <Col sm="8">
          <Input type="textarea" className="form-control comment-textarea" value={state.message} id="comment" placeholder="add your message" rows="10" onChange={(e)=>{setState({message:e.target.value})}}></Input>
        </Col>
      </Row>

      <br/>
      <br/>
      <br/>
      <center>
        <Button color="warning">Submit</Button>
      </center>
    </form></div>;
  }

  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <br/>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Appraisal - Fee and Turntime
          </CardHeader>
          <CardBody>
            <div className="well">
              <Row>
                <Col sm="4">
                  <label>Reference #</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.reference_num}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Loan #</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.loan_num}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Borrower</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Address</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}
                </Col>
              </Row>

              <br/><br/>
              <div className="my-divider"/>
              <br/>
              {content}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default BidResponse;
