//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDateTime} from '../../util/util';
import {Button, Col, Row, Card, CardHeader, CardBody, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import valid from "card-validator";

//initialize the state
const initialState = {
  appraisal:{
    reference_num:'',
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    loan_num:'',
    loan_purpose:'',
    loan_type:'',
    datetime_submitted:'0000-00-00',
    status:'',
    borrower_f_name:'',
    borrower_l_name:'',
  },

  ccNumberError:'',
  ccNumberValid:true,
  ccTypeError:'',
  ccTypeValid:true,
  loading: false,

  newcc_card_type:'',
  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_number:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  coveredBy:'',
  total:0,
  success:false,
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const UpdateCreditCard  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let invoiceFk = useParams().invoiceFk;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
    invoiceFk:invoiceFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    getTotalPending();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    validateCCNumber();
  },[state.newcc_card_number]);

  useEffect(()=>{
    validateCCType();
  },[state.newcc_card_type])

  //non API call but simpyl manage state
  // Validate the Credit Card Number
  const validateCCNumber=()=>{
    // console.log(state.newcc_card_number);
    let newCCNumber = state.newcc_card_number;

    let creditCard = valid.number(newCCNumber);
    let ccNumberError = "An unknown error occured. Please try again later";
    let ccNumberValid = false;

    if (newCCNumber === null || !newCCNumber.trim()) {
      ccNumberError = "Credit card number is not complete";
    } else if (creditCard.isValid) {
      ccNumberValid = true;
    } else {
      ccNumberError = "Credit card number is invalid";
    }

    setState({ccNumberValid: ccNumberValid, ccNumberError: ccNumberError});
    validateCCType();
  }

  // Validate the Credit Card Type
  const validateCCType=()=>{
    let newCCNumber = state.newcc_card_number;
    let newCCType = state.newcc_card_type;
    console.log(newCCType);

    let creditCard = valid.number(newCCNumber);
    let ccTypeError = "An unknown error occured. Please try again later";
    let ccTypeValid = false;

    //Mapping
    if(newCCType === "amex"){
      newCCType = "american-express";
    }else if(newCCType === "dinersclub"){
      newCCType = "diners-club";
    }

    //Card Type Verification
    if(!newCCType){
      ccTypeError = "Credit card type is not selected";
    }
    else if (newCCType === null || !newCCType.trim() || creditCard.card === null) {
      if(creditCard.card === null){
        ccTypeError = "";
        ccTypeValid = true;
      }else{
        ccTypeError = "Credit card type is not complete";
      }
    } else if (
      creditCard.card.type &&
      creditCard.card.type.toUpperCase() === newCCType.toUpperCase()
    ) {

      ccTypeValid = true;
    } else {
      ccTypeError = "Credit card type is invalid";
    }

    setState({ccTypeValid: ccTypeValid, ccTypeError: ccTypeError});
  }

  //API call
  const getAppraisal = () => {
    let callBack = apiCallBack([{state:'appraisal', key:'data'}]);
    httpGet('appraisal/public/'+state.id, '', 'Oops, something went wrong and could not load appraisal. Please try again later.', callBack);
  }

  const getTotalPending = () => {
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let total =0;
        if(response.data.data)
          total = response.data.data;
        setState({total:total});
      }
    }
    httpGet('billing/pendingCC/'+state.id, '', 'Oops, something went wrong and could not load total pending payment. Please try again later.', callBack);
  }

  const registerCC=(e)=>{
    e.preventDefault();
    if(!state.loading){
      setState({loading:true});
      let parameters = [
        {
          field:'ID',
          value:state.id
        },
        {
          field:'invoiceFk',
          value:state.invoiceFk
        },
        {
          field:'billing_first_name',
          value:state.newcc_first_name
        },
        {
          field:'billing_last_name',
          value:state.newcc_last_name
        },
        {
          field:'email',
          value:state.newcc_email
        },
        {
          field:'card_number',
          value:state.newcc_card_number
        },
        {
          field:'card_type',
          value:state.newcc_card_type
        },
        {
          field:'coveredBy',
          value:state.coveredBy
        },
        {
          field:'cvc',
          value:state.newcc_cvc
        },
        {
          field:'expiration_month',
          value:state.newcc_expiration_month
        },
        {
          field:'expiration_year',
          value:state.newcc_expiration_year
        },
        {
          field:'billing_street',
          value:state.newcc_street
        },
        {
          field:'billing_city',
          value:state.newcc_city
        },
        {
          field:'billing_state',
          value:state.newcc_state
        },
        {
          field:'billing_zip',
          value:state.newcc_zip
        }
      ];


      let callBack = (response)=>{
        let code= response.data.code;

        if(code==='00'){
          setState({success:true});
        }
      };

      let promises = httpPost('billing/creditCard/create',parameters, 'Credit card submitted successfully.','Oops, something went wrong and could not register the credit card. Please try again later.', callBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let expirationYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<20;i++){
    expirationYear.push(currentYear);
    currentYear++;
  }

  let warningMessage;

  if(state.appraisal.datetime_accepted==='0000-00-00 00:00:00')
    warningMessage = <div>
      <font color="red">This order has not yet been assigned so the fee may subject to change. We will sent an email out for the actual amount after it's been assigned.</font>
    </div>
  
  if(state.success){
    return(
      <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <br/><br/><br/><br/><br/><br/><br/><br/>
        <div className="my-well" style={{background:'#f5f5f5', marginLeft:'50px', marginRight:'50px'}}>
          <br/>
          <center>
            Thank you for your appraisal order. <br/>
            Your payment information has been received and your appraisal order is in process. <br/>
            Payment will be collected in the next 24-72 hours and a paid invoice will be delivered with your report once completed.<br/><br/><br/>
            You may now close this page.
          </center>
          <br/>
        </div>
      </div>
     </div>
    )
  }
  else{
    return(
      <div>
        <div className="topbar">
            <div className="topbar-logo-container">
              <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
            </div>
        </div>
        <div className="padding">
          <center>
            <font style={{fontSize:'15px'}}>
              <b>Appraisal fee for {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip} </b>
            </font>
          </center>
          <br/>
          <Row>
            <Col sm="1">

            </Col>
            <Col sm="10">
              <Card style={{background:'#f5f5f5'}}>
                <CardHeader className="header-color">
                  <center>
                     <i className="fa fa-cc-visa"></i> Register new credit card profile
                  </center>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      {warningMessage}
                    </Col>
                    <Col sm="4">
                      <div className="align-right">
                        <font style={{fontSize:'20px'}}>
                          Total Due: <b><u>${formatNumber(state.total)}</u></b>
                        </font>
                      </div>
                    </Col>
                  </Row>

                  <div style={{background:'white', border:'1px solid #d2d2d2',padding:'5px',borderRadius:'5px'}}>
                    <label>Reference #:&nbsp;&nbsp;</label>
                    {state.appraisal.reference_num}

                    <br/>
                    <label>Status:&nbsp;&nbsp;</label>
                    {state.appraisal.status}

                    <br/>
                    <label>Property address:&nbsp;&nbsp;</label>
                    {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}

                    <br/>
                    <label>Borrower:&nbsp;&nbsp;</label>
                    {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}

                    <br/>
                    <label>Loan purpose:&nbsp;&nbsp;</label>
                    {state.appraisal.loan_purpose}

                    <br/>
                    <label>Loan type:&nbsp;&nbsp;</label>
                    {state.appraisal.loan_type}

                    <br/>
                    <label>Loan #:&nbsp;&nbsp;</label>
                    {state.appraisal.loan_num}

                    <br/>
                    <label>Date submitted:&nbsp;&nbsp;</label>
                    {formatDateTime(state.appraisal.datetime_submitted)}

                    <br/>
                  </div>
                  <div className="my-divider"></div>

                  <form onSubmit={registerCC}>
                    <Card>
                      <CardHeader className="header-color">
                        <i className="fa fa-user"></i> Card holder information
                      </CardHeader>
                      <CardBody style={{background:'white'}}>
                        <Row>
                          <Col sm="6">
                            <Input type="text" required={true} placeholder="First name*" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
                          </Col>
                          <Col sm="6">
                            <Input type="text" required={true} placeholder="Last name*" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
                          </Col>
                          <Col sm="6">
                            <br/>
                            <Input type="text" placeholder="Email (optional, for receipt)" value={state.newcc_email} onChange={(e)=>setState({newcc_email:e.target.value})}/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <br/>
                    <Card>
                      <CardHeader className="header-color">
                        <i className="fa fa-credit-card"></i> Credit card information
                      </CardHeader>
                      <CardBody style={{background:'white'}}>
                        <Row>
                          <Col sm="6">
                            <Input type="select" className="form-control" required={true} value={state.newcc_card_type} onChange={(e)=>setState({newcc_card_type:e.target.value})}>
                              <option value="">Select card type</option>
                              <option value="visa">Visa</option>
                              <option value="mastercard">Master Card</option>
                              <option value="amex">American Express</option>
                              <option value="dinersclub">Dinners Club</option>
                              <option value="discover">Discover</option>
                            </Input>
                            <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
                          </Col>
                          <Col sm="3">
                            <Input type="select" className="form-control" required={true} value={state.coveredBy} onChange={(e)=>setState({coveredBy:e.target.value})}>
                              <option value="">Select paid by</option>
                              <option value="visa">Borrower</option>
                              <option value="mastercard">Broker</option>
                              <option value="amex">Lender</option>
                            </Input>
                          </Col>
                        </Row>
                        <br/>
                        <div>{!state.ccNumberValid&&<h6><font color="red">{'*** '+state.ccNumberError+' ***'}</font></h6>}</div>
                        <Row>
                          <Col sm="7">
                            <Input type="text" required={true} placeholder="Credit card number*" value={state.newcc_card_number} onChange={(e)=>setState({newcc_card_number:e.target.value})}/>
                          </Col>
                          <Col sm="5">
                            <Input type="text" required={true} placeholder="CVC*" value={state.newcc_cvc} onChange={(e)=>setState({newcc_cvc:e.target.value})}/>
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col sm="6">
                            <Input type="select" className="form-control" required="true" value={state.newcc_expiration_month} onChange={(e)=>setState({newcc_expiration_month:e.target.value})}>
                              <option value="">Select expiration month</option>
                              <option value="01">January</option>
                              <option value="02">Febuary</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </Input>
                          </Col>
                          <Col sm="6">
                            <Input type="select" className="form-control" required={true} value={state.newcc_expiration_year} onChange={(e)=>setState({newcc_expiration_year:e.target.value})}>
                              <option value="">Select expiration year</option>
                              {
                                expirationYear.map(
                                  (year,index)=>{
                                    return <option key={index} value={year}>{year}</option>
                                  }
                                )
                              }
                            </Input>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <br/>
                    <Card>
                      <CardHeader className="header-color">
                        <i className="fa fa-bank"></i> Billing address
                      </CardHeader>
                      <CardBody style={{background:'white'}}>
                        <Row>
                          <Col sm="8">
                            <Input type="text" required={true} placeholder="Street*" value={state.newcc_street} onChange={(e)=>setState({newcc_street:e.target.value})}/>
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col sm="4">
                            <Input type="text" required={true} placeholder="City*" value={state.newcc_city} onChange={(e)=>setState({newcc_city:e.target.value})}/>
                          </Col>
                          <Col sm="5">
                            <Input type="select" className="form-control" required={true} value={state.newcc_state} onChange={(e)=>setState({newcc_state:e.target.value})}>
                              <option value="">Select state</option>
                              {
                                state.states.map(
                                  (state,index)=>{
                                    return <option key={index} value={state.key}>{state.value}</option>
                                  }
                                )
                              }
                            </Input>
                          </Col>
                          <Col sm="3">
                            <Input type="text" required={true} placeholder="Zip*" value={state.newcc_zip} onChange={(e)=>setState({newcc_zip:e.target.value})}/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <br/><br/>

                    <center>
                      <Button disabled={state.loading} style={{width:'350px'}} color="warning" type="submit">Submit</Button>
                    </center>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col sm="1">

            </Col>
          </Row>

        </div>
      </div>
    );
  }
}


export default UpdateCreditCard;
