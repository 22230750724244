//Author June Yee Leow
//Date Oct 3, 2017
//Redux store
import allReducers from './reducers';
import {applyMiddleware,createStore} from 'redux';
import thunk from 'redux-thunk';

//APPLY MIDDLEWARE
const middleware = applyMiddleware(thunk);

//CREATE THE STORE
const store = createStore(allReducers,middleware);

export default store;
