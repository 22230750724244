//Author June Leow
//Date Jul 29th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber} from '../../util/util';
import {Button, Col, Row, Input, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import MyFormStep from '../../util/my-form-step';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import ReactDOM from 'react-dom';
//initialize the state
const initialState = {
  id:-1,
  error:'',
  appraisal:{},
  appraisals:[],
  isInternal:'',
  requesterName:'',
  requesterEmail:'',
  subjectFacts:'',
  other:'',
  conditionTypes:[],
  keyword:'',

  conditions:[
    {
      type:'Other',
      subject_facts:''
    }
  ],

  steps:['Search for order','Condition information','Review & Contact information'],
  maxStep:0,
  currentStep:0,

  toUploadFiles:[],
  errorMessage:'',
  fileTypes:[],
};

//reducer function that perform state update
const reducer = getReducer();


const NewCondition  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getConditionTypes();
    getFileTypes();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const getOther=(type)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        return conditions[i].other;
      }
    }
    return '';
  }

  const updateOther=(type, value)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        conditions[i].other = value;
        break;
      }
    }
    setState({conditions:conditions});
  }

  const finalVerification=()=>{
    if(state.requesterName==='')
      setState({error:'Please provide your name.'});
    if(state.requesterEmail==='')
      setState({error:'Please provide your email.'});
    if(state.requesterEmail!==''&&state.requesterName!=='')
      handleStepAdvance();
  }

  const conditionTypesOnChange=(type)=>{
    let conditions = state.conditions.slice();
    let index = -1;

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        index = i;
        break;
      }
    }

    if(index===-1){
      let tmp = {};
      tmp.type = type;
      tmp.other='';
      tmp.subject_facts='';
      conditions.push(tmp);
    }
    else
      conditions.splice(index,1);

    setState({conditions:conditions});
  }

  const selectAppraisal=(id)=>{
    setState({id:id});

    let appraisal={};
    for(let i=0;i<state.appraisals.length;i++){
      if(state.appraisals[i].ID===id){
        appraisal = state.appraisals[i];
        break;
      }
    }

    setState({appraisal:appraisal});
    handleStepAdvance();
  }

  const handleStepAdvance=()=>{
    if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
      ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;

    let step = state.currentStep+1;
    //step 3 then finish, send create appraisal request
    if(step===3)
      createNewCondition();
    //else advance the step
    else{
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep=(step)=>{
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const updateSubjectFacts=(type, value)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        conditions[i].subject_facts = value;
        break;
      }
    }
    setState({conditions:conditions});
  }

  const getSubjectFacts=(type)=>{
    let conditions = state.conditions.slice();

    for(let i=0;i<conditions.length;i++){
      if(conditions[i].type===type){
        return conditions[i].subject_facts;
      }
    }
    return '';
  }

  
  const verifyConditionInfo=()=>{
    let conditions = state.conditions.slice();

    if(conditions.length<=0){
      setState({error:'Please select the condition type.'});
    }
    else{
      let ok = true;
      for(let i=0;i<conditions.length;i++){
        if(!conditions[i].subject_facts||conditions[i].subject_facts===''){
          ok = false;
          conditions[i].error = 'Please state the subject facts.';
        }
      }

      setState({conditions:conditions});
      if(ok)
        handleStepAdvance();
    }
  }

  

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop=(acceptedFiles, rejectedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile=(preview)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize=(size)=>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  
  //constructing a new file object
  const deepCopyFileObject=(file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }


  //on change function when user change the file type drop down in upload file pop up
  const onFileTypeChange=(name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //API call
  const searchAppraisal=()=>{
    setState({error:''});

    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      if(code==='00'){
        setState({appraisals:response.data.data});
        if(response.data.data.length<=0)
          setState({error:'Record not found.'});
      }
    };
    httpGet('appraisal/search/'+state.keyword,'','Oops, something went wrong and could not search for appraisal. Please try again later.', callBack);
  }


  const getConditionTypes=()=>{
    const callBack = apiCallBack([{state:'conditionTypes', key:'data'}]);
    httpGet('condition/type/get','','Oops, something went wrong and could not load condition types. Please try again later.', callBack);
  }

  const createNewCondition=()=>{
    let preCheck = true;
    let errorMessage = '';

    let fileTypes = [];

    for(let i =0; i<state.toUploadFiles.length;i++){
      let value = state.toUploadFiles[i].fileType;

      if(!value||value===''){
        preCheck = false;
        fileTypes.push('');
        errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
      }
      else
        fileTypes.push(value);
    }

    if(preCheck){
      if(state.toUploadFiles.length>0){
        let files = [];
        for(let i=0;i<state.toUploadFiles.length;i++){
          const reader = new FileReader();
          reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let tmp = {};
            tmp.base64 = base64;
            tmp.name = state.toUploadFiles[i].name;
            tmp.type = fileTypes[i];

            files.push(tmp);
            if(files.length>=state.toUploadFiles.length){
              let url = 'condition/batch/create';
              let successMsg = 'Condition created successfully.';

              let errorMsg = 'Oops, something went wrong and could not create the condition. Please try again later.';

              let parameters = [
                {
                  field:'appraisalFk',
                  value:state.id
                },
                {
                  field:'requesterName',
                  value:state.requesterName
                },
                {
                  field:'requesterEmail',
                  value:state.requesterEmail
                },
                {
                  field:'conditions',
                  value:state.conditions
                },
                {
                  field:'files',
                  value:files
                }
              ];
              console.log(parameters);

              let callBack = (response)=>{
                let code= response.data.code;

                if(code==='00'){
                  history('/');
                }
              };

              httpPost('condition/batch/create', parameters, 'Condition created successfully.', 'Oops, something went wrong and could not create the condition. Please try again later.' , callBack);
            }
          };

          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => {
            props.showMessage('error','File upload failed, please try again later.');
          };

          reader.readAsBinaryString(state.toUploadFiles[i]);
        }
      }
      else{

        let parameters = [
          {
            field:'appraisalFk',
            value:state.id
          },
          {
            field:'requesterName',
            value:state.requesterName
          },
          {
            field:'requesterEmail',
            value:state.requesterEmail
          },
          {
            field:'conditions',
            value:state.conditions
          },
          {
            field:'files',
            value:state.toUploadFiles
          }
        ];
        console.log(parameters);

        let callBack = (response)=>{
          let code= response.data.code;
          if(code==='00'){
            history('/');
          }
        };
        
        httpPost('condition/batch/create', parameters, 'Condition created successfully.', 'Oops, something went wrong and could not create the condition. Please try again later.' , callBack);
      }
    }
    else{
      setState({errorMessage:errorMessage});
    }
  }

  const getFileTypes=()=>{
    const callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/public/get','','Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }


  //render
  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <select className="form-control no-padding" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }


  let step1, step2, step3;

  let results;

  if(state.appraisals.length>0)
    results =
    <div className="well padding">
      <font color="red">*</font>Please click on the appraisal order that you want to request condition.
      <div className="medium-scroll-container">
        <table className="table">
          <tbody style={{background:'white'}}>
            {
              state.appraisals.map(
                (appraisal, index)=>{
                  return(
                    <tr key={index} className="cursor-pointer" onClick={(e)=>selectAppraisal(appraisal.ID)}>
                      <td>
                        <b>{appraisal.reference_num +' - '+appraisal.loan_num}<br/></b>
                        {appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}<br/>
                        {appraisal.loan_type+', '+appraisal.loan_purpose}<br/>
                        {appraisal.appraisal_type}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>;

  step1 =
  <div>
    <div className="well padding">
      <font color="red">{state.error}</font><br/>
      <label>Reference number / Loan number</label>
      <Input type="text" value={state.keyword} onChange={(e)=>setState({keyword:e.target.value})} className="form-control"/>

      <br/>
      <Button color="warning" className="form-control" onClick={searchAppraisal}>Search</Button>
    </div>

    <br/>
    {results}
  </div>;



  let subjectFacts;

  subjectFacts = state.conditions.map(
    (condition,index)=>{
      let other;

      if(condition.type==='Other'){
        other =
        <Row>
          <Col sm="12">
            <label>Description</label>
            <Input type="text" value={getOther(condition.type)} onChange={(e)=>updateOther(condition.type,e.target.value)}/>
          </Col>
        </Row>
      }

      return(
        <div>
          <div><font color="red">{condition.error}</font></div>
          <b>Please state subject facts for condition request</b><br/>
          <Input type="textarea" value={getSubjectFacts(condition.type)} onChange={(e)=>updateSubjectFacts(condition.type,e.target.value)} rows="16" style={{resize:'none'}}></Input>
          <br/>
        </div>
      );
    }
  );

  step2 =
  <div className="well padding">
    <br/>
    {subjectFacts}
    <br/><br/>
    <div className="align-right">
      <Button color="warning" className="form-control" onClick={verifyConditionInfo}>Submit</Button>
    </div>
  </div>;

  step3 =
  <div>
    <div><font color="red">{state.error}</font></div>
    <b>Appraisal order information</b>
    <div className="well padding">
      <b>{state.appraisal.reference_num+' - '+state.appraisal.loan_num}</b><br/>
      {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}<br/>
      {state.appraisal.loan_type+', '+state.appraisal.loan_purpose}<br/>
      {state.appraisal.appraisal_type}<br/><br/>
    </div>
    <br/>
    <b>Condition information</b>
    <div className="well padding">
      {
        state.conditions.map(
          (condition, index)=>{
            return(
              <div key={index}>
                <b>{condition.type} {condition.other}</b><br/>
                <i>{condition.subject_facts}</i>
                <br/><br/>
              </div>
            );
          }
        )
      }
    </div>
    <br/>
    <label>Upload File (optional)</label><br/>
    <MyDropzone onDrop={onDrop}/>
    <br/>
    <div className="small-scroll-container red-color">
      <b>{state.errorMessage}</b>
    </div>
    <div>
      <table className="table file-list-table" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th width="30%">Name</th>
            <th width="35%">File Type</th>
            <th width="15%">Size</th>
            <th width="10%"><center>Status</center></th>
            <th width="10%">Control</th>
          </tr>
        </thead>
        <tbody>
          {toUploadFiles}
        </tbody>
      </table>
    </div>
    <br/>
    <b>Contact information</b>
    <div className="well padding">
      <Row>
        <Col sm="4">
          <Input type="text" value={state.requesterName} placeholder="Your name" onChange={(e)=>setState({requesterName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <Input type="text" value={state.requesterEmail} placeholder="Your email" onChange={(e)=>setState({requesterEmail:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button color="warning" onClick={finalVerification}>Submit Condition</Button>
        </Col>
      </Row>
    </div>
  </div>

  let activeStep = step1;
  if(state.currentStep==0)
    activeStep = step1;
  else if(state.currentStep==1)
    activeStep = step2;
  else if(state.currentStep==2)
    activeStep = step3;

  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Condition request
          </CardHeader>
          <CardBody>
            <div style={{background:'white'}}>
              <br/>
              <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
              <br/>
              {activeStep}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default NewCondition;
