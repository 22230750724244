//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import { NavLink, useParams} from 'react-router-dom';
//initialize the state
const initialState = {

};

//reducer function that perform state update
const reducer = getReducer();


const Article  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getArticle(state.id);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getArticle = (id)=>{
    let url = '';

    //set state
    //setState({loading:false});
    //setState({files:response.data.data, "CONCAT_SET_STATES"});

    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'title',key:'data.title'},{state:'body',key:'data.body'},{state:'creator',key:'data.creator'},{state:'datetimeCreated',key:'data.datetimeCreated'}]);
    //let callBack = apiCallBack([{state:'stateName',value:'some value'}]);

    httpGet('article/retrieve/'+id, '','Oops, something went wrong and could not load this Article. Please try again later.', callBack);
  }

  //render
  return(
    <div style={{background:"#ECECEC"}}>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <div className="padding">
        <div className="my-well" >
          <div>
            <h3>{state.title}</h3><b>By</b> {state.creator} - {formatDate(state.datetimeCreated)}
          </div>
          <div className="my-divider">&nbsp;</div>
          <div dangerouslySetInnerHTML={{__html:state.body}}/>
        </div>
      </div>
    </div>
  );
}


export default Article;
