//Author June Leow
//Date Nov 14th, 2023
import Article from './article/article';
import AnticipatedValue from './appraisal/anticipated-value';
import AppraiserDormant from './appraiser/dormant';
import BidResponse from './appraisal/bid-response';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import LoadingBar, { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import { logout } from '../actions/action-login';
import UpdateCreditCard from './payment/update-credit-card';
import Home from './home';
import Tracker from './tracker/tracker';
import config from '../config';
import ShippingPortal from './shipping/shipping-portal';
import PublicOrderVolumeReport from './report/public-order-volume-report';
import TurntimeReport from './report/turntime-report';
import Policy from './policy';
import ConditionSelect from './condition/condition-select';
import NewCondition from './condition/new-condition';
import ConditionUpdate from './condition/condition-update';
import RebuttalUpdate from './rebuttal/rebuttal-update';
import RebuttalSelect from './rebuttal/rebuttal-select';
import NewRebuttal from './rebuttal/new-rebuttal';

const App  = (props)=>{

  const container = useRef();

  return (
    <div>
      <div style={{height:'100%'}}>
        <ToastContainer theme="colored" className="my-toast-container always-on-top"/>
        <LoadingBar style={{zIndex:'99'}}/>
        <Router>
          <div id="contentContainer" className="content-container" ref={container}>
            <Routes>
              <Route
                exact path="/"
                element={<Home {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraisal/anticipated-value/:id"
                element={<AnticipatedValue {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraisal/bid-response/:id/:appraiserFk/:bidID"
                element={<BidResponse {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/appraiser/dormant/:id/:email"
                element={<AppraiserDormant {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/article/:id"
                element={<Article {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/payment/update-credit-card/:id"
                element={<UpdateCreditCard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/payment/update-credit-card/:id/:invoiceFk"
                element={<UpdateCreditCard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/shipping-portal"
                element={<ShippingPortal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/shipping-portal/:email/:referenceNum/:password"
                element={<ShippingPortal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/tracker"
                element={<Tracker {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/tracker/:referenceNum"
                element={<Tracker {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/report/public-order-volume/:params"
                element={<PublicOrderVolumeReport {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/report/turntime/:params"
                element={<TurntimeReport {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/policy"
                element={<Policy {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/condition/condition-select"
                element={<ConditionSelect {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/condition/new-condition"
                element={<NewCondition {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/condition/update-condition"
                element={<ConditionUpdate {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/rebuttal/rebuttal-select"
                element={<RebuttalSelect {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/rebuttal/new-rebuttal"
                element={<NewRebuttal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/rebuttal/update-rebuttal"
                element={<RebuttalUpdate {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
            </Routes>
          </div>
        </Router>
      </div>
    </div>
  );
}

function mapStateToProps(state){
  return {
    jwtToken: state.session.jwtToken,
    isLoggedIn: state.session.isLoggedIn,
    userRole: state.session.userRole,
    userFirstName: state.session.userFirstName,
    userLastName: state.session.userLastName
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    logout: logout,
    dispatchShowLoadingFunc: ()=>{return showLoading()},
    dispatchHideLoadingFunc: ()=>{return hideLoading()},
  },dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(App);
