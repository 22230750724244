//Author June Leow
//Date June 1st, 2023
import React, {useEffect} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HCMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";

HCMore(Highcharts);
SolidGauge(Highcharts);

const MyChart  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call

  //render
  return <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={props.options}
    />
  </div>;
}

export default MyChart;
