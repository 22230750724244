//Author June Leow
//Date June 1st, 2023
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import statesMap from "../../util/us-all-states.json";
import { getReducer, getSetStateFunction, getStateCode } from '../../util/util';

//initialize the state
const initialState = {
  state:'',
  stateCode:'',
};

//reducer function that perform state update
const reducer = getReducer();


const MyHeatMapDrillDown  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  highchartsMap(Highcharts);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    populateCountyMap(state.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //API call
  const chartComponent = useRef(null);
  let chartData = [];
  let map;

  const [options, setOptions] = useState({
    chart: {
      map: statesMap
    },
    title: {
      text: props.title
    },
    mapNavigation: {
      enabled: true,
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        floating: true,
        backgroundColor: ( // theme
            Highcharts.defaultOptions &&
            Highcharts.defaultOptions.legend &&
            Highcharts.defaultOptions.legend.backgroundColor
        ) || 'rgba(255, 255, 255, 0.85)'
    },
    colorAxis: {
        min: props.min?props.min:0,
        max: props.max?props.max:20,
        tickInterval: 5,
        stops: [[0, '#77E818'], [0.5, '#BFE818'], [0.7, '#E8E818'], [0.8,'#E88A18'],[1,'#E83518']],
        labels: {
          format: '{value} '+(props.dataLabel?props.dataLabel:props.title)
        }
    },
    plotOptions: {
      mapline: {
        showInLegend: false,
        enableMouseTracking: false
      }
    },
    series: [
      {
        data: [],
        mapData:map,
        joinBy: ['code'],
        name: props.title,
        borderWidth: 0.5,
        shadow: false,
        accessibility: {
            enabled: false
        },
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          format: '{point.name}'
        },
        cursor:'pointer',
        point: {
         events: {
          click: (e)=>{
            if(state.state===''){
              let stateCode = e.point.code.toLowerCase();
              stateCode = stateCode.replace('us-','');
              let state = e.point.name;
              setState({state:state, stateCode:stateCode});
              props.onClick(state);
            }
            else{
              let county = e.point.county;
              props.onClick(county,'county');
            }
          }
         }
       }
      },
      {
        type: 'mapline',
        name: 'State borders',
        color: 'white',
        shadow: false,
        borderWidth: 2,
        accessibility: {
          enabled: false
        }
      }
    ]
  });

  
  //non API call but simpyl manage state
  //update map options
  const populateCountyMap = (stateClicked)=>{   
    if(stateClicked!==''){
      map = require('../../util/high-chart/'+getStateCode(stateClicked).toLowerCase()+'.json');

      let propsData = props.data2; 
      for(let i=0;i<propsData.length;i++){
        let stateshort = propsData[i].state_short.toLowerCase();
        let county = propsData[i].county.toLowerCase().replace('county','').trim();

        let keyword2 = county+', '+stateshort;

        let counties = map.objects.default.geometries;

        for(let j=0;j<counties.length;j++){
          if(!counties[j].properties.name || !counties[j].properties['hc-key'])
            continue;
          let tokens =  counties[j].properties['hc-key'].split('-');

          let keyword1 = counties[j].properties.name.toLowerCase()+', '+tokens[1];

          if(keyword1===keyword2){
            let tmp = {
              code : counties[j].properties['hc-key'],
              name : propsData[i].county+', '+propsData[i].state_short,
              state : propsData[i].state,
              value: propsData[i].value
            };

            chartData.push(tmp);
            break;
          }
        }
      }
    }
    else{
      let states = statesMap.objects.default.geometries;
      let propsData = props.data;

      for(let i=0;i<propsData.length;i++){

        let keyword2 = propsData[i].state.toLowerCase();

        for(let j=0;j<states.length;j++){

          if(!states[j].properties.name)
            continue;

          let keyword1 = states[j].properties.name.toLowerCase();

          if(keyword1===keyword2){
            let tmp = {
              code : states[j].properties['hc-key'],
              name : propsData[i].state+', '+propsData[i].state_short,
              value: propsData[i].value
            };

            chartData.push(tmp);
            break;
          }
        }
      }
    }

    const newOptions = {
      chart: {
        map: statesMap
      },
      title: {
        text: props.title
      },
      mapNavigation: {
        enabled: true,
      },
      legend: {
          layout: 'vertical',
          align: 'right',
          floating: true,
          backgroundColor: ( // theme
              Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.backgroundColor
          ) || 'rgba(255, 255, 255, 0.85)'
      },
      colorAxis: {
          min: props.min?props.min:0,
          max: props.max?props.max:20,
          tickInterval: 5,
          stops: [[0, '#77E818'], [0.5, '#BFE818'], [0.7, '#E8E818'], [0.8,'#E88A18'],[1,'#E83518']],
          labels: {
            format: '{value} '+(props.dataLabel?props.dataLabel:props.title)
          }
      },
      plotOptions: {
        mapline: {
          showInLegend: false,
          enableMouseTracking: false
        }
      },
      series: [
        {
          data: chartData,
          mapData:map,
          joinBy: ['hc-key', 'code'],
          name: props.title,
          borderWidth: 0.5,
          shadow: false,
          accessibility: {
              enabled: false
          },
          dataLabels: {
            enabled: true,
            color: '#FFFFFF',
            format: '{point.name}'
          },
          cursor:'pointer',
          point: {
           events: {
            click: (e)=>{
              if(state.state===''){
                let stateCode = e.point.code.toLowerCase();
                stateCode = stateCode.replace('us-','');
                let state = e.point.name;
                
                state = state.split(', ');
                
                state = state[0];
                setState({state:state, stateCode:stateCode});
                props.onClick(state);
              }
              else{
                let county = e.point.options.name;
                props.onClick(county,'county');
              }
            }
           }
         }
        },
        {
          type: 'mapline',
          name: 'State borders',
          color: 'white',
          shadow: false,
          borderWidth: 2,
          accessibility: {
            enabled: false
          }
        }
      ]
    };

    setOptions(newOptions);
  }


  //render
  return <div className="padding">
    <div className="my-well">
      <div className="align-right" style={{background:'white'}}>
        {state.state!==''?<NavLink to="#" onClick={()=>{setState({state:'',stateCode:''}); props.onClick('')}}>Back to USA</NavLink>:null}
      </div>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={options}
        constructorType="mapChart"
      />
    </div>
  </div>;
}

const shouldComponentUpdate = (prevProps, nextProps) => {
  if (prevProps.state === nextProps.state) {
    return true                                    // donot re-render
  }
  
  return false                                     // will re-render
}

//this is how we handle should component update in function based
export default React.memo(MyHeatMapDrillDown, shouldComponentUpdate);
