//Author June Leow
//Date Jul 29th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator,formatNumber} from '../../util/util';
import {Button, Col, Row, Input, Card, CardHeader, CardBody} from 'reactstrap';
import DatePicker from 'react-datepicker';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import MyFormStep from '../../util/my-form-step';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import MyDropzone from '../util/my-dropzone';
import ReactDOM from 'react-dom';
import ReactQuill from 'react-quill';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
//initialize the state
const initialState = {
  id:-1,
  requesterName:'',
  requesterEmail:'',
  subjectFacts:'',
  comparables:[
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    },
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    },
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    }
  ],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  appraisals:[],
  appraisal:{},

  steps:['Search for order','Rebuttal information','Comparables','Review & Contact information'],
  maxStep:0,
  currentStep:0,
  error:'',

  toUploadFiles:[],
  errorMessage:'',
  fileTypes:[],
};

//reducer function that perform state update
const reducer = getReducer();


const NewRebuttal  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getFileTypes();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const verifyRebuttalInfo=()=>{
    if(state.subjectFacts!=='')
      handleStepAdvance();
    else
      setState({error:'Please briefly describe the rebuttal.'})
  }

  const selectAppraisal=(id)=>{
    setState({id:id});

    let appraisal={};
    for(let i=0;i<state.appraisals.length;i++){
      if(state.appraisals[i].ID===id){
        appraisal = state.appraisals[i];
        break;
      }
    }

    setState({appraisal:appraisal});
    handleStepAdvance();
  }


  const handleStepAdvance=()=>{
    if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
      ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;

    let step = state.currentStep+1;
    //step 3 then finish, send create appraisal request
    if(step===4)
      createNewRebuttal();
    //else advance the step
    else{
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep=(step)=>{
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const updateComparableInfo=(index, field, value)=>{
    let comparables = state.comparables.slice();
    let target = Object.assign({},comparables[index]);
    target[field] = value;

    comparables.splice(index, 1, target);

    setState({comparables:comparables});
  }

  const removeComparables=(index)=>{
    let comparables = state.comparables.slice();
    comparables.splice(index,1);

    setState({comparables:comparables});
  }

  const adddComparables=()=>{
    let comparable = {};
    comparable.street='';
    comparable.city='';
    comparable.county='';
    comparable.state='';
    comparable.zip='';
    comparable.salePrice='';
    comparable.saleDate='';
    comparable.sqFootage='';
    comparable.age='';
    comparable.siteSize='';
    comparable.sourceData='';
    comparable.apnMlsNum='';
    comparable.comment='';
    comparable.status='';

    let comparables = state.comparables.slice();
    comparables.push(comparable);

    setState({comparables:comparables});
  }

  const finalVerification=()=>{
    if(state.requesterName==='')
      setState({error:'Please provide your name.'});
    if(state.requesterEmail==='')
      setState({error:'Please provide your email.'});
    if(state.requesterEmail!==''&&state.requesterName!=='')
      handleStepAdvance();
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop=(acceptedFiles, rejectedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile=(preview)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize=(size)=>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  //constructing a new file object
  const deepCopyFileObject=(file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }


  //on change function when user change the file type drop down in upload file pop up
  const onFileTypeChange=(name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //API call
  const getFileTypes=()=>{
    const callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/public/get','','Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  const createNewRebuttal=()=>{
    let preCheck = true;
    let errorMessage = '';

    let fileTypes = [];

    for(let i =0; i<state.toUploadFiles.length;i++){
      let value = state.toUploadFiles[i].fileType;


      if(!value||value===''){
        preCheck = false;
        fileTypes.push('');
        errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
      }
      else
        fileTypes.push(value);
    }


    if(preCheck){
      if(state.toUploadFiles.length>0){
        let files = [];
        for(let i=0;i<state.toUploadFiles.length;i++){
          const reader = new FileReader();
          reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let tmp = {};
            tmp.base64 = base64;
            tmp.name = state.toUploadFiles[i].name;
            tmp.type = fileTypes[i];

            files.push(tmp);


            if(files.length>=state.toUploadFiles.length){
              let parameters = [
                {
                  field:'appraisalFk',
                  value:state.id
                },
                {
                  field:'isInternal',
                  value:state.isInternal
                },
                {
                  field:'requesterName',
                  value:state.requesterName
                },
                {
                  field:'requesterEmail',
                  value:state.requesterEmail
                },
                {
                  field:'subjectFacts',
                  value:state.subjectFacts
                },
                {
                  field:'comparables',
                  value:state.comparables
                },
                {
                  field:'files',
                  value:files
                }
              ];

              let callBack = (response)=>{
                console.log(response);
                let code= response.data.code;

                if(code==='00'){
                  history('/');
                }
              };
              httpPost('rebuttal/create', parameters, 'Rebuttal created successfully.', 'Oops, something went wrong and could not create the rebuttal. Please try again later.', callBack);
            }
          };
          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => {
            props.showMessage('error','File upload failed, please try again later.');
          };

          reader.readAsBinaryString(state.toUploadFiles[i]);
        }
      }
      else{
        let parameters = [
          {
            field:'appraisalFk',
            value:state.id
          },
          {
            field:'isInternal',
            value:state.isInternal
          },
          {
            field:'requesterName',
            value:state.requesterName
          },
          {
            field:'requesterEmail',
            value:state.requesterEmail
          },
          {
            field:'subjectFacts',
            value:state.subjectFacts
          },
          {
            field:'comparables',
            value:state.comparables
          }
        ];
        let callBack = (response)=>{
          let code= response.data.code;

          if(code==='00'){
            props.history.push('/');
          }
        };

        httpPost('rebuttal/create', parameters, 'Rebuttal created successfully.', 'Oops, something went wrong and could not create the rebuttal. Please try again later.', callBack);
      }
    }

    else{
      setState({errorMessage:errorMessage});
    }
  }

  const searchAppraisal=()=>{
    setState({error:''});

    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      if(code==='00'){
        setState({appraisals:response.data.data});
        if(response.data.data.length<=0)
          setState({error:'Record not found.'});
      }
    };
    httpGet('appraisal/search/'+state.keyword,'','Oops, something went wrong and could not search for appraisal. Please try again later.', callBack);
  }

  //render
  let filesTypesOpt;

  if(state.fileTypes.length>0){
    filesTypesOpt = state.fileTypes.map(
      (fileType, index)=>{
        return(
          <option key={index} value={fileType.name}>{fileType.name}</option>
        );
      }
    );
  }

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <select className="form-control no-padding" onChange={(e)=>{onFileTypeChange(file.name,e.target.value)}}>
                <option value=""></option>
                {filesTypesOpt}
              </select>
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let results;

  if(state.appraisals.length>0)
    results =
    <div className="well padding">
      <font color="red">*</font>Please click on the appraisal order that you want to request rebuttal.
      <div className="medium-scroll-container">
        <table className="table">
          <tbody style={{background:'white'}}>
            {
              state.appraisals.map(
                (appraisal, index)=>{
                  return(
                    <tr key={index} className="cursor-pointer" onClick={(e)=>selectAppraisal(appraisal.ID)}>
                      <td>
                        <b>{appraisal.reference_num +' - '+appraisal.loan_num}<br/></b>
                        {appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}<br/>
                        {appraisal.loan_type+', '+appraisal.loan_purpose}<br/>
                        {appraisal.appraisal_type}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>;

  let states;
  if(state.states.length>0)
    states = state.states.map(
      (state, index)=>{
        return <option value={state.key} key={index}>{state.value}</option>;
      }
    );


    let comparables;

    if(state.comparables.length>0){
      comparables = state.comparables.map(
        (comparable,index)=>{
          let date = null;
          if(comparable.saleDate&&comparable.saleDate!=='')
            date = moment(comparable.saleDate).toDate();
          return(
            <div key={index}>
              <div className="well padding">
                <Row>
                  <Col sm="8">
                    <b>Comparable {index+1}</b>
                  </Col>
                  <Col sm="4" className="align-right">
                    <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeComparables(index)}></i>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <label>Street</label>
                    <Input type="text" value={comparable.street} onChange={(e)=>updateComparableInfo(index,'street',e.target.value)}/>
                  </Col>
                  <Col sm="4">
                    <label>City</label>
                    <Input type="text" value={comparable.city} onChange={(e)=>updateComparableInfo(index,'city',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>County</label>
                    <Input type="text" value={comparable.county} onChange={(e)=>updateComparableInfo(index,'county',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <label>States</label>
                    <Input type="select" className="form-control" value={comparable.state} onChange={(e)=>updateComparableInfo(index,'state',e.target.value)}>
                      <option value=""></option>
                      {states}
                    </Input>
                  </Col>
                  <Col sm="4">
                    <label>Zip</label>
                    <Input type="text" value={comparable.zip} onChange={(e)=>updateComparableInfo(index,'zip',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Living area</label>
                    <Input type="text" value={comparable.sqFootage} onChange={(e)=>updateComparableInfo(index,'sqFootage',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Site area</label>
                    <Input type="text" value={comparable.siteSize} onChange={(e)=>updateComparableInfo(index,'siteSize',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Age</label>
                    <Input type="text" value={comparable.age} onChange={(e)=>updateComparableInfo(index,'age',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Status</label>
                    <Input type="select" className="form-control"  value={comparable.status} onChange={(e)=>updateComparableInfo(index,'status',e.target.value)}>
                      <option value=""></option>
                      <option value="Closed">Closed</option>
                      <option value="Listing">Listing</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Sale price</label>
                    <Input type="text" value={comparable.salePrice} onChange={(e)=>updateComparableInfo(index,'salePrice',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Sale date</label><br/>
                    <DatePicker
                      className="form-control" 
                      onChangeRaw={(e)=>{e.preventDefault()}}
                      selected={date}
                      onChange={(text)=>{(text)&&updateComparableInfo(index,'saleDate',text.toLocaleDateString("en-CA"))}}
                    />
                  </Col>
                  <Col sm="3">
                    <label>APN/MLS Number</label>
                    <Input type="text" value={comparable.apnMlsNum} onChange={(e)=>updateComparableInfo(index,'apnMlsNum',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Source data</label>
                    <Input type="text" value={comparable.sourceData} onChange={(e)=>updateComparableInfo(index,'sourceData',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <label>Comment</label>
                    <Input type="textarea" rows="6" style={{resize:'none'}} value={comparable.comment} onChange={(e)=>updateComparableInfo(index,'comment',e.target.value)}/>
                  </Col>
                </Row>
              </div>
              <br/>
            </div>
          );
        }
      );
    }

  let step1, step2, step3, step4;

  step1 =
  <div>
    <div className="well padding">
      <font color="red">{state.error}</font><br/>
      <label>Reference number / Loan number</label>
      <Input type="text" value={state.keyword} onChange={(e)=>setState({keyword:e.target.value})} className="form-control"/>

      <br/>
      <Button color="warning" onClick={searchAppraisal} className="form-control">Search</Button>
    </div>

    <br/>
    {results}
  </div>;

  step2 =
  <div className="well padding">
    <font color="red">{state.error}</font>
    <Row>
      <Col sm="12">
        <label>Subject facts</label><br/>
        <ReactQuill
          value={state.subjectFacts}
          onChange={(value) => setState({subjectFacts:value})}
        />
      </Col>
    </Row>


    <br/>
    <div className="align-right">
      <Button color="warning" onClick={verifyRebuttalInfo}>Submit Rebuttal</Button>
    </div>
  </div>;

  step3 =
  <div className="well-padding">
    <div className="align-right">
      <NavLink to="#" onClick={adddComparables}><i className="fa fa-plus green-color"></i> Add new comparable</NavLink>
    </div>
    {comparables}

    <br/>
    <div className="align-right">
      <Button color="warning" onClick={handleStepAdvance}>Next</Button>
    </div>
  </div>

  step4 =
  <div>
    <div><font color="red">{state.error}</font></div>
    <b>Appraisal order information</b>
    <div className="well padding">
      <b>{state.appraisal.reference_num+' - '+state.appraisal.loan_num}</b><br/>
      {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}<br/>
      {state.appraisal.loan_type+', '+state.appraisal.loan_purpose}<br/>
      {state.appraisal.appraisal_type}<br/><br/>
    </div>
    <br/>
    <b>Rebuttal information</b>
    <div className="well padding">
      <i><div dangerouslySetInnerHTML={{__html:state.subjectFacts}}/></i>
      <br/><br/>
      {
        state.comparables.map(
          (comparable,index)=>{
            return(
              <div className="well padding">
                <b>Comparable {index+1}</b><br/>
                <Row>
                  <Col sm="12">
                    <label>Address</label><br/>
                    {comparable.street+' '+comparable.city+', '+comparable.state+' '+comparable.zip}
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Living area</label><br/>
                    {comparable.sqFootage}
                  </Col>
                  <Col sm="3">
                    <label>Site area</label><br/>
                    {comparable.siteSize}
                  </Col>
                  <Col sm="3">
                    <label>Age</label><br/>
                    {comparable.age}
                  </Col>
                  <Col sm="3">
                    <label>Status</label><br/>
                    {comparable.status}
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Sale price</label><br/>
                    {comparable.salePrice}
                  </Col>
                  <Col sm="3">
                    <label>Sale date</label><br/>
                    {comparable.saledate}
                  </Col>
                  <Col sm="3">
                    <label>APN/MLS number</label><br/>
                    {comparable.apnMlsNum}
                  </Col>
                  <Col sm="3">
                    <label>Source data</label><br/>
                    {comparable.sourceData}
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <label>Comment</label><br/>
                    <i><div dangerouslySetInnerHTML={{__html:comparable.comment}}/></i>
                  </Col>
                </Row>
              </div>
            )
          }
        )
      }
    </div>
    <br/>
    <label>Upload File (optional)</label><br/>
    <MyDropzone onDrop={onDrop}/>
    <br/>
    <div className="small-scroll-container red-color">
      <b>{state.errorMessage}</b>
    </div>
    <div>
      <table className="table file-list-table" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th width="30%">Name</th>
            <th width="35%">File Type</th>
            <th width="15%">Size</th>
            <th width="10%"><center>Status</center></th>
            <th width="10%">Control</th>
          </tr>
        </thead>
        <tbody>
          {toUploadFiles}
        </tbody>
      </table>
    </div>
    <br/>
    <b>Contact information</b>
    <div className="well padding">
      <Row>
        <Col sm="4">
          <Input type="text" value={state.requesterName} placeholder="Your name" onChange={(e)=>setState({requesterName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <Input type="text" value={state.requesterEmail} placeholder="Your email" onChange={(e)=>setState({requesterEmail:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button color="warning" onClick={finalVerification}>Submit Rebuttal</Button>
        </Col>
      </Row>
    </div>
  </div>

  let activeStep = step1;
  if(state.currentStep==0)
    activeStep = step1;
  else if(state.currentStep==1)
    activeStep = step2;
  else if(state.currentStep==2)
    activeStep = step3;
  else if(state.currentStep==3)
    activeStep = step4;

  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Rebuttal request
          </CardHeader>
          <CardBody>

            <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
            <br/>
            {activeStep}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default NewRebuttal;
