//Author Sooyoung Kim
//Date July 21, 2023
import React, {useEffect} from 'react';

const MyFormStep  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //render
  let steps;
  if(props.steps.length>0){
    steps = props.steps.map(
      (step, index)=>{
        if(index===props.currentStep){
          return(
            <li key={index} className="active" onClick={()=>props.updateStep(index)}>
              {step}
            </li>
          );
        }
        else{
          return(
            <li key={index} className="disabled" onClick={()=>props.updateStep(index)}>
              {step}
            </li>
          );
        }
      }
    );
  }
  return <section>
    <div className="wizard">
      <ul className="nav nav-wizard">
        {steps}
      </ul>
    </div>
  </section>;
}


export default MyFormStep;
