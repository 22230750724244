//Author June Leow
//Date Jul 29th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Card, CardHeader, CardBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
//initialize the state
const initialState = {

};

//reducer function that perform state update
const reducer = getReducer();


const ConditionSelect  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const batchNewEntitySearch = ()=>{
    let url = '';

    //set state
    //setState({loading:false});
    //setState({files:response.data.data, "CONCAT_SET_STATES"});

    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'stateName',key:'data'}]);
    //let callBack = apiCallBack([{state:'stateName',value:'some value'}]);

    httpGet(url, '','Oops, something went wrong and could not search for client entity. Please try again later.', callBack);
  }

  //render
  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <br/>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Condition request menu
          </CardHeader>
          <CardBody>
            <div className="well">
              <ul>
                <li>
                  <NavLink to="/condition/new-condition">Requesting a new condition</NavLink><br/>
                  <i>Choose this if you want to submit a brand new condition request.</i>
                  <br/><br/>
                </li>
                <li>
                  <NavLink to="/condition/update-condition">Update existing condition request</NavLink><br/>
                  <i>Choose this if you already submitted a condition and providing updates to the existing condition.</i>
                </li>
              </ul>
              <br/><br/>
              <font color="red">*</font>If you have any question regarding matter of condition please email <a href="mailto:conditions@homevms.com" target="_top">conditions@homevms.com</a> for assiting.
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default ConditionSelect;
