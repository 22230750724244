//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../../util/util';
import { NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  completed:false
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserDormant  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let email = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id,
    email:email
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    dormant();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const dormant = () => {
    let parameters = [
      {
        field:'appraiserFk',
        value:state.id
      },
      {
        field:'email',
        value:state.email
      }
    ];
    console.log(parameters)
    let callBack = apiCallBack([{state:'completed', value:true}]);
    httpPost('appraiser/dormant', parameters, 'You have been unsubscribed from our emails.', 'Oops, something went wrong and could not unsubscribe. Please try again later.', callBack);
  }

  //render
  let message;

  if(state.completed){
    message = <center>You have been unsubscribed from our emails, you may close this page now.</center>
  }
  else{
    message = <center>Unsubscribing you from our emails...</center>
  }

  return (
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <br/><br/><br/><br/>
        {message}
      </div>
    </div>
  )
}


export default AppraiserDormant;
