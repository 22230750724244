//Author June Leow
//Date Jul 24th, 2024
import React, {useEffect} from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const Policy  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //render
  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <br/>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Privacy Policy
          </CardHeader>
          <CardBody>
            <div className="well">
              @Home believes the responsible use of customer information collected through our website and is critical to our business objectives and reputation. Federal law requires us to tell you how we collect, use, share and protect your personal information.

              <br/><br/>We will not sell or otherwise provide customer nonpublic personal information to any third parties for marketing purposes.

              <br/><br/>@Home has technological and operational security policies and procedures in place to protect customer information from loss, mishandling or unintentional misuse. We follow generally accepted industry standards to protect nonpublic personal information submitted to use, both during transmission and once we receive it.

              <br/><br/>No method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially accepted means to protect nonpublic personal information, we cannot and   do not guarantee its absolute security.

              <br/><br/>@Home makes no warranties, expressed or implied and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability. @Home does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise related to such materials or on any sites linked to/from this site.

              <br/><br/>We reserve the right to modify or amend this Privacy Policy at any time. Changes will be made immediately to the website.

            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default Policy;
