//Author June Leow
//Date Jul 25th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, showMessage} from '../../util/util';
import {Row, Col, Table, Nav, NavItem, TabPane, TabContent, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

//initialize the state
const initialState = {
  id: -1,
  referenceNum:'',
  popUpOpen: false,
  appraisal:{
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
    borrower_f_name:'',
    borrower_l_name:''
  },
  timeline:[],
  trackerActiveTab:'5',
  appraisalLogs:[],
  conditionRebuttalLogs:[],
  paymentLogs:[],
  shippingLogs:[],
  allLogs:[],
  appraisals:[]
};

//reducer function that perform state update
const reducer = getReducer();


const Tracker  = (props)=>{
  const controller = new AbortController();

  let referenceNum = useParams().referenceNum;
  let newInitialState = Object.assign({}, initialState, {
    referenceNum:referenceNum?referenceNum:'',
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.referenceNum!=='')
      searchAppraisalID();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //toggle function between tab
  const trackerActiveTabToggle=(tab)=>{
    setState({trackerActiveTab:tab});
  }

  const selectAppraisal=(ID)=>{
    setState({appraisals:[]});
    setState({id:ID});
    getAppraisal(ID);
    getTrackLog(ID);
  }

  const customSort=(a, b)=>{
    if(a.datetime_created<b.datetime_created)
      return -1;
    if(a.datetime_created>b.datetime_created)
      return 1;
    return 0;
  }

  //API call
  //get appraisal
  const searchAppraisalID=()=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        if(response.data.data.length===1){
          setState({id:response.data.data[0].ID});

          getAppraisal(response.data.data[0].ID);
          getTrackLog(response.data.data[0].ID);
        }
        else{
          setState({appraisals:response.data.data});
        }
      }
      else{
        showMessage('error', 'No appraisal found.');
      }
    };
    httpGet('tracker/multiSearch/'+state.referenceNum, '', '', callBack);
  }

  //get appraisal
  const getAppraisal=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        setState({appraisal:response.data.data});
        if(response.data.data.special_instructions!=='')
          setState({dropdownOpen:true})
        setState({old_special_instructions:response.data.data.special_instructions});

        //order the date variable for tracker
        let appraisal = response.data.data;

        //submitted date always present
        let timeline = [];
        let temp = {};
        temp.stage = 'Order Submitted';
        temp.date = appraisal.datetime_submitted;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Assignment Accepted';
        temp.date = appraisal.datetime_accepted;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Inspection Scheduled';
        temp.date = appraisal.datetime_scheduled;
        timeline.push(temp);

        temp = {};
        temp.stage = 'In Review';
        temp.date = appraisal.datetime_review_started;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Shipping';
        temp.date = appraisal.datetime_review_completed;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Order Delivered';
        temp.date = appraisal.datetime_completed;
        timeline.push(temp);

        setState({timeline:timeline});
      }
    };
    callBack = callBack.bind(this);

    httpGet('tracker/appraisal/'+id,'','Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get track log
  const getTrackLog=(id)=>{
    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let appraisalLogs = response.data.data.appraisal;

        let keyword = 'sent assignment to ';
        for(let i=0;i<appraisalLogs.length;i++){
          let index = appraisalLogs[i].message.indexOf(keyword);
          if(index!==-1){
            let str = appraisalLogs[i].message.substring(0, index+keyword.length);
            str +='appraiser.';
            appraisalLogs[i].message = str;
          }
        }

        let conditionRebuttalLogs = response.data.data.condition;
        let paymentLogs = response.data.data.payment;
        let shippingLogs = response.data.data.shipping;
        let allLogs = appraisalLogs.concat(conditionRebuttalLogs).concat(paymentLogs).concat(shippingLogs);

        allLogs.sort(customSort);

        setState({appraisalLogs:appraisalLogs,conditionRebuttalLogs:conditionRebuttalLogs,paymentLogs:paymentLogs,shippingLogs:shippingLogs,allLogs:allLogs});
      }
    };
    
    httpGet('tracker/'+id,'','Oops, something went wrong and could not load this appraisal\'s tracking information. Please try again later.', callBack);
  }

  //render
  if(state.appraisals.length>0){
    return(
      <div>
        <div className="topbar">
            <div className="topbar-logo-container">
              <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
            </div>
        </div>
        <div className="padding">
          <form name="loginForm" onSubmit={(e)=>{e.preventDefault();searchAppraisalID();}}>
            <div className="well padding">
              <div className="form-group" style={{height:'38px'}}>
                <label className="font-blue bold"><b>Reference/Loan #:</b></label>
                <input type="text"  name="referenceNum" autoComplete="new-password" value={state.referenceNum} onChange={e => setState({referenceNum: e.target.value})} className="form-control" id="referenceNum"/>
              </div>
              <br/>
              <input type="submit" className="btn btn-blue-noshadow btn-block font-white" value="Search"></input>
              <br/>
            </div>
          </form>
          <div className="my-divider">&nbsp;</div>
          <div className="well padding">
            <font color="red">*</font>Please click on the appraisal order that you want to track.
            <div className="medium-scroll-container">
              <table className="table">
                <tbody style={{background:'white'}}>
                  {
                    state.appraisals.map(
                      (appraisal, index)=>{
                        return(
                          <tr key={index} className="cursor-pointer" onClick={(e)=>selectAppraisal(appraisal.ID)}>
                            <td>
                              <b>{appraisal.reference_num +' - '+appraisal.loan_num}<br/></b>
                              {appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}<br/>
                              {appraisal.external_label}
                            </td>
                          </tr>
                        );
                      }
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  let timeline;
  let appraisalType;

  if(state.appraisal.appraisal_type){
    appraisalType = state.appraisal.appraisal_type;
  }

  if(state.timeline.length>0){
    timeline = state.timeline.map(
      (entry,index)=>{
        let status = '';
        if(entry.date!=='0000-00-00 00:00:00')
          status = 'complete';
        return(
          <li key={index} className={"li "+status}>
            <div className="timestamp">
              <span className="date">
                {formatDateTime(entry.date)}
              </span>
            </div>
            <div className="status">
              <br/>{entry.stage}
            </div>
          </li>
        );
      }
    );
  }

  let appraisalLogs, conditionRebuttalLogs, paymentLogs, shippingLogs, allLogs;

  if(state.appraisalLogs.length>0){
    appraisalLogs = state.appraisalLogs.map(
      (appraisalLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(appraisalLog.datetime_created)}</td>
            <td>{appraisalLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.conditionRebuttalLogs.length>0){
    conditionRebuttalLogs = state.conditionRebuttalLogs.map(
      (conditionRebuttalLogs, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(conditionRebuttalLogs.datetime_created)}</td>
            <td>{conditionRebuttalLogs.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.paymentLogs.length>0){
    paymentLogs = state.paymentLogs.map(
      (paymentLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(paymentLog.datetime_created)}</td>
            <td>{paymentLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.shippingLogs.length>0){
    shippingLogs = state.shippingLogs.map(
      (shippingLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(shippingLog.datetime_created)}</td>
            <td>{shippingLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.allLogs.length>0){
    allLogs = state.allLogs.map(
      (shippingLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(shippingLog.datetime_created)}</td>
            <td>{shippingLog.message}</td>
          </tr>
        );
      }
    );
  }

  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <form name="loginForm" onSubmit={(e)=>{e.preventDefault();searchAppraisalID();}}>
          <div className="well padding">
            <div className="form-group" style={{height:'38px'}}>
              <label className="font-blue bold"><b>Reference/Loan #:</b></label>
              <input type="text"  name="referenceNum" autoComplete="new-password" value={state.referenceNum} onChange={e => setState({referenceNum: e.target.value})} className="form-control" id="referenceNum"/>
            </div>
            <br/>
            <input type="submit" className="btn btn-blue-noshadow btn-block font-white" value="Search"></input>
            <br/>
          </div>
        </form>
        <div className="align-right">
          <a href={"https://customer.homevms.com/appraisal/"+state.id}>Click here to login to the order details if you have an account with us</a>
        </div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Tracker
          </CardHeader>
          <CardBody className="no-padding">
            <div className="padding" style={{background:'white'}}>
              <NavLink to="/">Back to home page</NavLink>
              <br/>
              <div className="timeline">
                {timeline}
              </div>
              <br/>

              <div>
                <label>Due Date:&nbsp;</label>
                {formatDateTime(state.appraisal.datetime_expected)}<br/>
                <label>Property:&nbsp;</label>
                {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}
                &nbsp;&nbsp;
                <label>Borrower:&nbsp;</label>
                {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                <label>Loan Number:&nbsp;</label>
                {state.appraisal.loan_num}
                &nbsp;&nbsp;
                <label>Property Type:&nbsp;</label>
                {state.appraisal.property_type}
                &nbsp;&nbsp;
                <label>Loan Type:&nbsp;</label>
                {state.appraisal.loan_type}
                &nbsp;&nbsp;
                <label>Appraisal Type:&nbsp;</label>
                {appraisalType}
              </div>
              <br/>
              <Row>
                <Col sm="12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={"cursor-pointer nav-link "+(state.trackerActiveTab === '5'?"active":"inactive" )}
                        onClick={() => { trackerActiveTabToggle('5'); }}
                      >
                        All <span className="my-badge">{state.allLogs.length}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={"cursor-pointer nav-link "+(state.trackerActiveTab === '1'?"active":"inactive" )}
                        onClick={() => { trackerActiveTabToggle('1'); }}
                      >
                        Appraisal <span className="my-badge">{state.appraisalLogs.length}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={"cursor-pointer nav-link "+(state.trackerActiveTab === '2'?"active":"inactive" )}
                        onClick={() => { trackerActiveTabToggle('2'); }}
                      >
                        Condition/Rebuttal <span className="my-badge">{state.conditionRebuttalLogs.length}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={"cursor-pointer nav-link "+(state.trackerActiveTab === '4'?"active":"inactive" )}
                        onClick={() => { trackerActiveTabToggle('4'); }}
                      >
                        Shipping <span className="my-badge">{state.shippingLogs.length}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={state.trackerActiveTab}>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <div className="large-scroll-container">
                            <Table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="header-color" width="15%">Date</th>
                                  <th className="header-color" >Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allLogs}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div className="large-scroll-container">
                            <Table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="header-color" width="15%">Date</th>
                                  <th className="header-color" >Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {appraisalLogs}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <div className="large-scroll-container">
                            <Table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="header-color" width="15%">Date</th>
                                  <th className="header-color" >Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {conditionRebuttalLogs}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <div className="large-scroll-container">
                            <Table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="header-color" width="15%">Date</th>
                                  <th className="header-color" >Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                {shippingLogs}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default Tracker;
