//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, formatNumber} from '../../util/util';
import {Button, Col, Row, Input, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
//initialize the state
const initialState = {
  appraisal:{},
  anticipatedValue:{},
  name:'',
  value:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AnticipatedValue  = (props)=>{
  const controller = new AbortController();

  let id = useParams().id;
  let newInitialState = Object.assign({}, initialState, {
    id:id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisal();
    getAnticipatedValueLog();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getAnticipatedValueLog = ()=>{
    let callBack = apiCallBack([{state:'anticipatedValue',key:'data'}]);
    httpGet('appraisal/anticipatedValue/get/'+state.id, '','Oops, something went wrong and could not load anticipated value history. Please try again later.', callBack);
  }

  const updateAnticipatedValue = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'name',
        value:state.name
      },
      {
        field:'value',
        value:state.value
      },
      {
        field:'referenceNum',
        value:state.appraisal.reference_num
      },
      {
        field:'loanNum',
        value:state.appraisal.loan_num
      }
    ];
   
    let callBack = apiCallBack([{state:'anticipatedValue', key:'data'}]);
    httpPut('appraisal/client/anticipatedValue/update', parameters, 'Anticipated value update successfully. You may now close this page.', 'Oops, something went wrong and could not update the anticipated value. Please try again later.', callBack);
  }

  const getAppraisal = () => {
    let callBack = apiCallBack([{state:'appraisal', key:'data'}]);
    httpGet('appraisal/public/'+state.id, '', 'Oops, something went wrong and could not load appraisal. Please try again later.', callBack);
  }

  //render
  let anticipatedHistory;

  if(state.anticipatedValue.value){
    anticipatedHistory = <div>
      {state.anticipatedValue.name+' updated the estimated value to $'+formatNumber(state.anticipatedValue.value)+' at '+formatDateTime(state.anticipatedValue.datetime_created)}
    </div>
  }
  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <br/>
      <br/>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Appraisal - Estimated Value
          </CardHeader>
          <CardBody>
            <div className="well">
              <Row>
                <Col sm="4">
                  <label>Reference #</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.reference_num}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Loan #</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.loan_num}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Borrower</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Address</label>
                </Col>
                <Col sm="8">
                  {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}
                </Col>
              </Row>

              <br/><br/>
              <div className="my-divider"/>
              <br/>
              {anticipatedHistory}

        
              <br/><br/>

              <form onSubmit={updateAnticipatedValue}>
                <Row>
                  <Col sm="4">
                    <label>Estimated Value</label>
                  </Col>
                  <Col sm="8">
                    <Input required={true} input type="text" pattern="[0-9]*"value={state.value} onChange={(e)=>setState({value:e.target.value})}/>
                  </Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col sm="4">
                    <label>Your Name</label>
                  </Col>
                  <Col sm="8">
                    <Input required={true} type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
                  </Col>
                </Row>

                <br/>
                <center>
                  <Button color="warning">Submit</Button>
                </center>
              </form>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default AnticipatedValue;
