//Author June Yee Leow
//Date Oct 3, 2017
//Reducer for application related operation like showing loading bar
export default function application(state={
  showLoading: false
}, action){
  switch(action.type){
    case "SHOW_LOADING":
      let newState = Object.assign({},state,{showLoading:true});
      return newState;
    default:
      return state;
  }
}
