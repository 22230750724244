//Author June Leow
//Date Nov 15th, 2023
import React from 'react';
import { NavLink } from 'react-router-dom';

const MyToast  = (props)=>{
  //render
  if(props.link&&props.link!=='')
    return(
      <div>
        <NavLink target="_blank" to={props.link} style={{color:'white',textDecoration:'none'}}>
          <div><b>{props.from}</b></div>
          <div>{props.content}</div>
        </NavLink>
      </div>
    );
  else
    return(
      <div>
        <div><b>{props.from}</b></div>
        <div>{props.content}</div>
      </div>
    );
}


export default MyToast;
