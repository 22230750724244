//Author June Yee Leow
//Date Oct 20, 2017
//This script serve as a container that holds all the configuration like API base URL, Integration credential etc
const config = {
  baseAPIUrl: 'https://newapi.homevms.com/',
  pusherAppKey: 'e6bd47e51f08937d63f1',
  pusherCluster: 'us2'
};

export default config;
