//Author June Leow
//Date Jul 24th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../util/util';
import React, {useReducer, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
//initialize the state
const initialState = {
  referenceNum:'',
};

//reducer function that perform state update
const reducer = getReducer();


const Home  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(() => {
    const myDiv = document.getElementById('contentContainer');
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar-fixed-top');
      console.log(myDiv);
      console.log(myDiv.scrollTop);
      if (myDiv && myDiv.scrollTop > 50) { // Adjust this value based on your preference
        navbar.classList.add('sticky-navbar');
      } else {
        navbar.classList.remove('sticky-navbar');
      }
    };
    
    if (myDiv) {
      myDiv.addEventListener('scroll', handleScroll);
    }

    const scrollToHash = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Scroll on component mount if hash exists
    scrollToHash();

    // Scroll on hash change
    window.addEventListener('hashchange', scrollToHash);

    return () => {
      controller.abort();
      window.removeEventListener('hashchange', scrollToHash);
      if(myDiv){
        myDiv.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //non API call but simpyl manage state
  const scrollToHashWithHash=(hash)=>{
    // Remove the '#' from the hash to get the element's ID
    const targetId = hash.startsWith('#') ? hash.slice(1) : hash;
  
    // Find the target element by ID
    const targetElement = document.getElementById(targetId);
  
    if (targetElement) {
      // Change the URL without reloading the page
      window.history.pushState(null, null, hash);
  
      // Scroll to the target element with smooth scrolling
      targetElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element with ID '${targetId}' not found.`);
    }
  }

  const trackOrder=()=>{
    history('/tracker/'+state.referenceNum);
  }

  //API call
 

  //render
  return(
    <div>
      <header id="header">
        <nav className="navbar st-navbar st-navbar-float navbar-fixed-top" id="headerNav">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#st-navbar-collapse">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <NavLink to="#"><img src="../img/logoMain.png" alt="" className="logo"/></NavLink>
            </div>

            <div className="collapse navbar-collapse" id="st-navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#about-us')}>About</NavLink></li>
                <li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#services')}>Services</NavLink></li>


                <li className="dropdown">
                  <a data-toggle="dropdown" className="dropdown-toggle" href="#" style={{backgroundColor:"transparent"}}>Orders <b className="caret"></b></a>
                  <ul className="dropdown-menu" >
                    <li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#orders')}>Order Status</NavLink></li>
                    <li><NavLink to="/shipping-portal">Download Appraisal</NavLink></li>
                    <li><NavLink to="/condition/condition-select">Condition</NavLink></li>
                    <li><NavLink to="/rebuttal/rebuttal-select">Rebuttal</NavLink></li>
                  </ul>
                </li>


                <li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#place-order')}>Clients</NavLink></li>
                <li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#appraisers')}>Appraisers</NavLink></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section id="slider">
        <div id="home-carousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="item active" style={{backgroundImage:'url("/img/main-backdrop.jpg")', backgroundAttachment:'fixed', backgroundPosition: 'center center',backgroundRepeat: 'no-repeat',}}>
              <div className="carousel-caption container">
                <div className="row">
                  <div className="col-sm-offset-5 col-sm-7">
                    <h1>Appraisal Management</h1>
                    <h2>Advanced Technology with the highest quality service</h2>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h1>About</h1>
                <span className="st-border"></span>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us">
              <h2><i className="fa fa-home"></i> Who we are</h2>
              <p style={{fontSize:"16px"}}>@Home VMS is a California-based, nationwide appraisal management company started in 2009 to satisfy HVCC requirements. Our mission is to make the appraisal process both efficient and transparent to its customers while maintaining appraiser independence.</p>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us">
              <h2><i className="fa fa-users"></i> Investment in Our People</h2>
              <p style={{fontSize:"16px"}}>Our service starts with our team of professionals. This is why we dedicate our resources to seeking out seasoned professional appraisers with proven geographic competency, and who will provide the best appraisal quality in their marketplace. Working with @Home VMS is like having an in-house appraisal management company within your corporate structure.</p>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us">
              <h2><i className="fa fa-cogs"></i> What Sets Us Apart</h2>
              <p style={{fontSize:"16px"}}>We know the perfect partnership begins with a common goal, to create a win-win scenario in all aspects of the business appraisal process. This is why we focus our attention on providing the following key benefits:</p>
            </div>
            <div className="col-md-5 col-sm-5 col-md-offset-1 st-about-us">
              <h3><i className="fa fa-envelope"></i> Invitation-Only Appraisal Management Team</h3>
              <p>@HomeVMS appraisers are required to meet a rigorous set of guidelines, as well as pass our proprietary appraiser scoring system. This invitation only philosophy ensures a panel of seasoned professional appraisers that provide the high quality, quick turnaround times, and market knowledge that our clients deserve.</p>
            </div>
            <div className="col-md-1 col-sm-1"></div>
            <div className="col-md-5 col-sm-5  st-about-us">
              <h3><i className="fa fa-check-square-o"></i> Quality</h3>
              <p>Every appraisal goes through a three-tiered quality control process before release to ensure your report meets standard UAD guidelines and client overlays. Our focused geographic footprint, and knowing you are working with a market expert is the key to great quality.</p>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us"></div>
            <div className="col-md-5 col-sm-5 col-md-offset-1 st-about-us">
              <h3><i className="fa fa-bolt"></i> Speed</h3>
              <p>Strong relationships with our team of appraisers assure our clients the best turn times in your marketplace.</p>
            </div>
            <div className="col-md-1 col-sm-1"></div>
            <div className="col-md-5 col-sm-5 st-about-us">
              <h3><i className="fa fa-phone"></i> Customer Service</h3>
              <p>A dedicated Live Customer Service Team means having someone available to answer your phone calls and emails. Our goal is to quickly respond to your needs and provide the best response to help get your transaction on its way to a happy and successful close.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h1>Services</h1>
                <span className="st-border"></span>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us">
              <p>@Home VMS provides a complete line of appraisal management services across the nation. We provide the highest quality for residential appraisals and alternative valuation products. Our easy-to-use valuation management portal allows you to track activity on your appraisals with ease of use. As a nationwide Appraisal Management Company, we are responsible for effective communication with all parties during the appraisal transaction. This is the bellwether of our service.</p>
            </div>
            <div className="col-md-4 col-sm-6 st-service">
              <h2><i className="fa fa-desktop"></i> Nationwide Appraiser Panel</h2>
              <p>At the point of appraiser selection, our proprietary software allows us to view the appraiser’s distance to the property while simultaneously comparing the appraiser’s area of expertise prior to assigning the order. This process ensures confidence in quality and accuracy for our valued clients.</p>
            </div>
            <div className="col-md-4 col-sm-6 st-service">
              <h2><i className="fa fa-cogs"></i> Order Level Quality Control</h2>
              <p>Our technology enables us to seamlessly flow from order placement and QC and conditions, and on to report completion. In addition, @Home VMS utilizes its resources of an in-house experienced appraisal team to help ensure compliance.</p>
            </div>
            <div className="col-md-4 col-sm-6 st-service">
              <h2><i className="fa fa-code"></i> Appraiser Performance</h2>
              <p>Monitoring appraiser performance is imperative to ensuring continued quality and customer satisfaction. Our enhanced scoring system tracks appraiser performance through several quality metrics, including rebuttal requests, outcomes, average number of condition requests, and order-level review deficiencies.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="place-order">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title"><h1>Place an Order</h1><span className="st-border"></span></div>
            </div>
            <div className="col-md-12 col-sm-12"><br/></div>
            <div className="col-md-6 col-sm-6">
              <div className="st-orders text-center">
                <h5><i className="fa fa-pencil-square-o"></i> Need an account? Join us today</h5>
                <div className="st-border"></div>
                <ul><p>With our easy process, we can get you signed up today by emailing us (orders@homevms.com). You will be able to place an appraisal order in no time.</p></ul>
                <a href="mailto:orders@homevms.com" className="btn btn-send"><i className="fa fa-plus-square-o"></i> Contact Us</a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="st-orders text-center">
                <h5><i className="fa fa-user"></i> Existing Client Login</h5>
                <div className="st-border"></div>
                <ul><p>If you are an existing Lender or Broker, please login to place your order.</p></ul>
                <a href="https://customer.homevms.com" className="btn btn-send"><i className="fa fa-sign-in"></i> Client Login</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="orders">
        <div className="container">
          <div className="row">
            <div className="overlay"></div>
            <div className="col-md-12">
              <div className="orders-title"><h1>Order Status</h1><span className="st-border"></span></div>
            </div>
            <div className="col-md-12 col-sm-12 st-about-us">
              <p>To Communicate with an @Home Team Member or to find out the status of your appraisal, please enter the Reference/Loan number below:</p>
            </div>
            <div className="col-md-12 col-sm-12 st-service">
              <div className="st-orders text-center">
                <h5><i className="fa fa-search"></i> Track My Order</h5>
                <div className="st-border"></div>
                <ul><p>Please enter the order reference number.<br/>The reference number can be found within the body of the status update emails you receive regularly.</p></ul>
                <div className="contact-form">
                    <div className="row">
                        <div className="col-sm-9">
                            <input type="text" name="reference_num" required="required" placeholder="Reference/Loan Number*" value={state.referenceNum} onChange={(e)=>setState({referenceNum:e.target.value})}/>
                        </div>
                        <div className="col-sm-2">
                          <input onClick={trackOrder} type="submit" value="Submit" className="btn btn-send"/>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="appraisers">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h1>Appraisers</h1>
              </div>
            </div>
            <div className="st-appraisers-box col-md-12 col-sm-12">
              <div className="col-md-12 col-sm-12 st-appraisers">
                <h2><i className="fa fa-users"></i> Existing Appraisers</h2>
                <p>If you are an existing @Home appraiser and are part of our appraiser panel, please log in.</p>
                <a href="https://appraise.homevms.com/" className="btn btn-send"><i className="fa fa-sign-in"></i> Appraiser Login</a>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 st-appraisers-box">
              <div className="col-md-12 col-sm-12 st-appraisers">
                <h2><i className="fa fa-user"></i> New Appraisers</h2>
                <p>Be part of @Home by emailing us (orders@homevms.com). We are always looking for talented appraisers nationwide. With our excellent customer service team, we will handle most of the administrative tasks and clerical duties for you, so you can be in the field appraising residential properties.</p>
              </div>
              <div className="col-md-12 col-sm-12 st-appraisers">
                <h2><i className="fa fa-check"></i> We are looking for</h2>
                <ul>
                  <li>Valid E&O insurance ($500,000 aggregate)</li>
                  <li>Current License</li>
                  <li>A current resume</li>
                  <li>Appraisers with at least 1,000 units of experience</li>
                  <li>Two recent UAD-compliant samples reports within the last six months</li>
                  <li>Coverage counties and product fees</li>
                  <li>W9</li>
                </ul>
                <p> Thank you for your interest in us.</p>
                <a href="https://appraise.homevms.com/sign-up" className="btn btn-send"><i className="fa fa-plus-square-o"></i> Sign Up</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="overlay"></div>
            <div className="col-md-12">
              <div className="contact-title">
                <h1>Contact us</h1>
                <span className="st-border"></span>
              </div>
            </div>
            <div className="col-sm-12 contact-info">
            <p className="st-address"><i className="fa fa-map-marker"></i> <strong>@Home VMS<br/>77 Van Ness Avenue, Suite 101<br/>San Francisco, CA 94102</strong></p>
            <p className="st-phone" style={{fontFamily:"Aria"}}><i className="fa fa-mobile"></i> <strong>(415) 614 3880</strong></p>
            <p className="st-email"><i className="fa fa-envelope-o"></i> For Appraisers in need of assistance, please email <strong>service@homevms.com</strong> </p>
            <p className="st-email"><i className="fa fa-envelope-o"></i> For Clients in need of assistance please call or email <strong>orders@homevms.com</strong> </p>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer">
        <div className="container">
          <div className="row">
              <div className="col-sm-6 col-sm-push-6 footer-social-icons">
                <span>Follow us:</span>
                <a href="https://www.facebook.com/homevms/" target="blank"><i className="fa fa-facebook"></i></a>
                <a href="https://www.linkedin.com/company/alexander-mccabe" target="blank"><i className="fa fa-linkedin"></i></a>
              </div>
              <div className="col-sm-6 col-sm-pull-6 copyright">
                <p>&copy; {new Date().getFullYear()} <a href="">@Home VMS</a>. All Rights Reserved. | <a target="_blank" href="/policy">Privacy Policy</a></p>
              </div>
          </div>
        </div>
      </footer>
      <div className="scroll-up">
      <ul><li><NavLink to="#" onClick={(e)=>scrollToHashWithHash('#header')}><i className="fa fa-angle-up"></i></NavLink></li></ul>
    </div>
    </div>
  );
}


export default Home;
