//Author June Leow
//Date Jul 29th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyFormStep from '../../util/my-form-step';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
//initialize the state
const initialState = {
  appraisals:[],
  conditions:[],
  appraisal:{},
  condition:{},

  subjectFacts:'',
  requesterName:'',
  requesterEmail:'',
  keyword:'',

  steps:['Search for order','Select condition','Update & Contact information'],
  maxStep:0,
  currentStep:0,
};

//reducer function that perform state update
const reducer = getReducer();


const ConditionUpdate  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal, noToken:true});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const finalVerification=()=>{
    if(state.subjectFacts==='')
      setState({error:'Please briefly describe the update on this condition.'});
    if(state.requesterName==='')
      setState({error:'Please provide your name.'});
    if(state.requesterEmail==='')
      setState({error:'Please provide your email.'});
    if(state.requesterEmail!==''&&state.requesterName!==''&&state.subjectFacts!=='')
      handleStepAdvance();
  }

  const selectAppraisal=(id)=>{
    setState({id:id});

    let appraisal={};
    for(let i=0;i<state.appraisals.length;i++){
      if(state.appraisals[i].ID===id){
        appraisal = state.appraisals[i];
        break;
      }
    }

    setState({appraisal:appraisal});
    handleStepAdvance();
    getConditionTickets(id);
  }

  const selectCondition=(id)=>{
    setState({id:id});

    let condition={};
    for(let i=0;i<state.conditions.length;i++){
      if(state.conditions[i].ID===id){
        condition = state.conditions[i];
        break;
      }
    }

    setState({condition:condition});
    handleStepAdvance();
  }

  const handleStepAdvance=()=>{
    let step = state.currentStep+1;
    //step 3 then finish, send create appraisal request
    if(step===3)
      updateCondition();
    //else advance the step
    else{
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep=(step)=>{
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  //API call
  const searchAppraisal=()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      if(code==='00'){
        setState({appraisals:response.data.data});
        if(response.data.data.length<=0)
          setState({error:'Record not found.'});
      }
    };
    httpGet('appraisal/search/'+state.keyword,'','Oops, something went wrong and could not search for appraisal. Please try again later.', callBack);
  }

  const getConditionTickets=(id)=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data.code;
      if(code==='00'){
        setState({conditions:response.data.data});
        if(response.data.data.length<=0)
          setState({error:'No condition tickets.'});
      }
    };

    httpGet('condition/external/'+id,'','Oops, something went wrong and could not retrieve condition tickets. Please try again later.',callBack);
  }

  const updateCondition=()=>{
    let parameters = [
      {
        field:'ID',
        value:state.condition.ID
      },
      {
        field:'requesterName',
        value:state.requesterName
      },
      {
        field:'requesterEmail',
        value:state.requesterEmail
      },
      {
        field:'subjectFacts',
        value:state.subjectFacts
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        history('/');
      }
    };
    httpPut('condition/subjectFacts/update', parameters, 'Condition update successfully.','Oops, something went wrong and could not update the condition. Please try again later.', callBack);
  }

  //render
  let step1, step2, step3;

  let results;

  if(state.appraisals.length>0)
    results =
    <div className="well padding">
      <font color="red">*</font>Please click on the appraisal order that you want to update condition.
      <div className="medium-scroll-container">
        <table className="table">
          <tbody style={{background:'white'}}>
            {
              state.appraisals.map(
                (appraisal, index)=>{
                  return(
                    <tr key={index} className="cursor-pointer" onClick={(e)=>selectAppraisal(appraisal.ID)}>
                      <td>
                        <b>{appraisal.reference_num +' - '+appraisal.loan_num}<br/></b>
                        {appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}<br/>
                        {appraisal.loan_type+', '+appraisal.loan_purpose}<br/>
                        {appraisal.appraisal_type}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>;

  let results2;

  if(state.conditions.length>0)
    results2 =
    <div className="well padding">
      <font color="red">*</font>Please click on the condition ticket that you want to update.
      <div className="medium-scroll-container">
        <table className="table">
          <tbody style={{background:'white'}}>
            {
              state.conditions.map(
                (condition, index)=>{
                  return(
                    <tr key={index} className="cursor-pointer" onClick={(e)=>selectCondition(condition.ID)}>
                      <td>
                        <b>{condition.type} - {condition.requester_name}<br/></b>
                        <i><div dangerouslySetInnerHTML={{__html:condition.subject_facts}}/></i>
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>;

  step1 =
  <div>
    <div className="well padding">
      <font color="red">{state.error}</font><br/>
      <label>Reference number / Loan number</label>
      <Input type="text" value={state.keyword} onChange={(e)=>setState({keyword:e.target.value})} className="form-control"/>

      <br/>
      <Button color="warning" onClick={searchAppraisal} className="form-control">Search</Button>
    </div>

    <br/>
    {results}
  </div>;

  step2 =
  <div>
    <font color="red">{state.error}</font><br/>
    <div className="well padding">
      <b>{state.appraisal.reference_num +' - '+state.appraisal.loan_num}<br/></b>
      {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}<br/>
      {state.appraisal.loan_type+', '+state.appraisal.loan_purpose}<br/>
      {state.appraisal.appraisal_type}
    </div>
    <br/><br/>
    {results2}
  </div>;

  step3 =
  <div>
    <font color="red">{state.error}</font><br/>
    <div className="well padding">
      <b>{state.appraisal.reference_num +' - '+state.appraisal.loan_num}<br/></b>
      {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}<br/>
      {state.appraisal.loan_type+', '+state.appraisal.loan_purpose}<br/>
      {state.appraisal.appraisal_type}
    </div>
    <br/><br/>
    <div className="well padding">
      <b>{state.condition.type +' - '+state.condition.requester_name}<br/></b>
      <i><div dangerouslySetInnerHTML={{__html:state.condition.subject_facts}}/></i>
    </div>
    <br/><br/>
    <div className="well padding">
      <b>Updates</b><br/>
      <Input type="textarea" rows="4" value={state.subjectFacts} onChange={(e)=>setState({subjectFacts:e.target.value})}></Input>

      <br/>
      <Row>
        <Col sm="4">
          <Input type="text" value={state.requesterName} placeholder="Your name" onChange={(e)=>setState({requesterName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <Input type="text" value={state.requesterEmail} placeholder="Your email" onChange={(e)=>setState({requesterEmail:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button color="warning" onClick={finalVerification} className="form-control">Update Condition</Button>
        </Col>
      </Row>
    </div>
  </div>;

  let activeStep = step1;
  if(state.currentStep==0)
    activeStep = step1;
  else if(state.currentStep==1)
    activeStep = step2;
  else if(state.currentStep==2)
    activeStep = step3;

  return(
    <div>
      <div className="topbar">
          <div className="topbar-logo-container">
            <NavLink to="/"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
          </div>
      </div>
      <div className="padding">
        <Card>
          <CardHeader className="header-color">
            Condition update
          </CardHeader>
          <CardBody>
            <div style={{background:'white'}}>
              <br/>
              <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
              <br/>
              {activeStep}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


export default ConditionUpdate;
