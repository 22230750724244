//Author June Yee Leow
//Date Oct 3, 2017
//Entry point that combine all reducers into one
import ApplicationReducer from './reducer-application';
import {combineReducers} from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar'
import SessionReducer from './reducer-session';

const allReducers = combineReducers({
  session: SessionReducer,
  application: ApplicationReducer,
  loadingBar: loadingBarReducer
});

export default allReducers;
